import type { ComponentProps } from "react";
import Image from "next/image";
import Link from "next/link";
import { renderRichText } from "@storyblok/react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Avatar } from "@/components/layout/Avatar";
import { Headline } from "@/components/typography/Headline";
import { Subline } from "@/components/typography/Subline";
import { RichText } from "@/components/typography/RichText";
import type { TextImageStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type TextImageProps = { blok: TextImageStoryblok } & ComponentProps<"article">;

const TextImage = ({ blok, ...rest }: TextImageProps) => {
  const team = blok.team as any;

  return (
    <>
      {blok.text && blok.headline ? (
        <article
          className={cn(
            "bg-white rounded-3xl flex flex-col-reverse md:flex-row gap-14 p-6 mb-8",
            {
              ["md:flex-row-reverse"]: blok.reverse,
            },
            rest.className
          )}
          {...storyblokEditable(blok)}
        >
          <div className="md:w-3/5 flex p-4 md:p-14 flex-col items-start">
            <Subline>{blok.subline}</Subline>
            <Headline as="h3" size="medium" className="mb-4">
              {blok.headline}
            </Headline>
            <RichText
              html={renderRichText(blok.text)}
              className="text-xl mb-4"
            />

            {team && (
              <Avatar
                image={team.content?.image?.filename}
                name={team.content?.subheadline}
                text={team.content?.headline}
              />
            )}

            {blok.link && blok.button && (
              <Link href={`/${blok?.link?.cached_url}` || "#"}>
                <Button className="rounded-full text-sm">
                  <ArrowRightIcon className="mr-1 h-4 w-4" />
                  {blok.button}
                </Button>
              </Link>
            )}
          </div>
          <div className="md:w-2/5">
            <Image
              src={blok.image?.filename}
              alt={blok.image?.alt || ""}
              width={420}
              height={420}
              className={cn(
                "w-full h-full aspect-square object-cover rounded-2xl",
                {
                  ["object-contain"]: blok.contain,
                }
              )}
            />
          </div>
        </article>
      ) : (
        <article
          className={cn("bg-white rounded-3xl p-6 mb-8", rest.className)}
        >
          <Image
            src={blok.image?.filename}
            alt={blok.image?.alt || ""}
            width={420}
            height={420}
            className="w-full max-h-[420px] object-cover object-top rounded-2xl"
          />
        </article>
      )}
    </>
  );
};

export default TextImage;
