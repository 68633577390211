import Link from "next/link";
import Image from "next/image";
import type { ComponentProps } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { track } from "@vercel/analytics";

import { Badge } from "@/components/ui/badge";
import type { AdStoryblok, CampaignStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type AdProps = {
  blok: AdStoryblok;
  campaign?: CampaignStoryblok;
  label?: string;
} & ComponentProps<"div">;

const Ad = ({ blok, campaign, label = "Werbung", ...rest }: AdProps) => {
  const ad = (blok.ad as AdStoryblok | undefined) || blok;

  if (!ad?.content) {
    return null;
  }

  return (
    <div
      className={cn(
        {
          ["lg:fixed lg:max-w-96 lg:top-48"]: ad.content?.sticky,
        },
        rest.className
      )}
    >
      <Badge variant="secondary" className="font-normal mb-1">
        {label}
      </Badge>
      <Link
        {...storyblokEditable(ad)}
        href={ad.content?.link?.cached_url || "#"}
        target={ad.content?.link?.target || "_self"}
        onClick={() => {
          track("Ad click", {
            uid: ad._uid,
            name: ad.name,
            campaign: campaign?.name,
          });
        }}
        aria-label="Werbung anzeigen"
      >
        {ad.content?.image && (
          <Image
            src={ad.content?.image.filename}
            alt={ad.content?.image.alt || ""}
            sizes="(max-width: 768px) 100vw, 30vw"
            className="w-full h-full object-contain"
            width={970}
            height={90}
          />
        )}
      </Link>
    </div>
  );
};

export default Ad;
