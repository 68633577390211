import Autoplay from "embla-carousel-autoplay";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import type { LogoListStoryblok } from "@/component-types-sb";

type LogoListProps = {
  blok: LogoListStoryblok;
};

const LogoList = ({ blok }: LogoListProps) => (
  <div
    {...storyblokEditable(blok)}
    className="container md:max-w-7xl md:mx-auto flex flex-col items-center gap-4 mx-auto px-6 lg:px-8 md:py-12 py-6"
  >
    <Headline as="h3" size="tall">
      {blok.headline}
    </Headline>
    <CopyText>{blok.text}</CopyText>

    {blok.carousel ? (
      <Carousel
        id="logo-list"
        plugins={[
          Autoplay({
            delay: 4000,
          }),
        ]}
        opts={{
          loop: true,
          align: "center",
          slidesToScroll: 5,
        }}
      >
        <CarouselContent className="-ml-2 md:-ml-4 items-center gap-4 md:gap-0">
          {blok.items?.map((nestedBlok: any) => (
            <CarouselItem
              className="pl-2 md:pl-4 basis-1/2 md:basis-1/3 lg:basis-1/5"
              key={nestedBlok._uid}
            >
              <StoryblokComponent blok={nestedBlok} />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    ) : (
      <div className="flex items-center gap-12 my-8 flex-wrap">
        {blok.items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    )}
  </div>
);

export default LogoList;
