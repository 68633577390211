import type { ComponentProps } from "react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";

import { Hero as HeroComponent } from "@/components/layout/Hero";
import { Animation } from "@/components/media/Animation";
import type { HeroColor } from "@/components/layout/Hero";
import type { HeroStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type HeroProps = ComponentProps<"section"> & {
  blok: HeroStoryblok;
};

const Hero = ({ blok, ...rest }: HeroProps) => {
  if (blok.variant === "secondary") {
    return (
      <HeroComponent
        headline={blok.headline}
        cta={blok.cta?.[0]}
        shape="shape2"
        color={blok.color as HeroColor}
        className="break-container"
      />
    );
  }

  return (
    <section
      className={cn(
        "bg-sand-50 relative px-4 md:px-14 pt-48 z-0 min-h-[620px] break-container",
        {
          "pb-16 min-h-fit pt-24 md:pt-48": !blok.animated,
        },
        rest.className
      )}
      {...storyblokEditable(blok)}
    >
      {blok.animated && (
        <div className="absolute top-0 right-0 w-full h-60 -z-10">
          <Animation />
        </div>
      )}

      <div
        className={cn("container max-w-7xl mx-auto pt-32", {
          "pt-0": !blok.animated,
        })}
      >
        <h1 className="text-2.5xl md:text-5xl mb-4 max-w-2xl leading-tight">
          {blok.headline}
        </h1>

        <div className="flex flex-col md:flex-row gap-6 items-center h-16 mt-8">
          {blok.cta?.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
