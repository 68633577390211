"use client";

import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { FileTextIcon } from "lucide-react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

import type { PressSectionStoryblok } from "@/component-types-sb";
import { Card } from "@/components/ui/card";
import { Headline } from "@/components/typography/Headline";
import { SocialIcons } from "@/components/typography/SocialIcons";
import type { AssetStoryblok } from "@/component-types-sb";

type PressSectionProps = {
  blok: PressSectionStoryblok;
};

const PressSection = ({ blok }: PressSectionProps) => (
  <section
    className="flex flex-col md:flex-row gap-6 my-20"
    {...storyblokEditable(blok)}
  >
    <Card className="p-8 bg-sand-50 rounded-3xl shadow-none md:w-2/3">
      <div className="flex flex-col items-center justify-center space-y-3">
        <Headline as="h4" size="medium">
          {blok.title}
        </Headline>

        <Collapsible className="flex flex-col">
          <CollapsibleTrigger>
            <div className="bg-sun-100 text-black h-full rounded-full hover:bg-sun-75 px-5 py-2.5">
              {blok.cta}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="grid md:grid-cols-3 gap-12 pt-8 items-start justify-center">
            {blok.assets?.map((asset: AssetStoryblok) => (
              <Link href={asset.filename} target="_blank" key={asset.filename}>
                <Image
                  src={asset.filename}
                  alt={asset.alt || ""}
                  className="w-full h-full aspect-square object-contain object-left-top mt-4"
                  width={213}
                  height={320}
                />
              </Link>
            ))}
            <a
              href="https://usohuo7cfsbfaqro.public.blob.vercel-storage.com/dialetics_2024_pressetext-oIklB8YrXysnONlEJUqXTMIJoV262Z.pdf"
              target="_blank"
            >
              <FileTextIcon className="w-20 h-20 m-4 text-black" />
            </a>
          </CollapsibleContent>
        </Collapsible>
      </div>
    </Card>

    <Card className="p-8 bg-sand-50 rounded-3xl shadow-none md:w-1/3">
      <div className="space-y-6">
        <div>
          <Headline as="h4" size="medium">
            Press inquiries
          </Headline>
          <a href={`mailto:${blok.email}`} className="text-lg hover:underline">
            {blok.email}
          </a>
        </div>
        <div>
          <Headline as="h4" size="medium">
            Social Media
          </Headline>
          <div className="flex space-x-4">
            <SocialIcons />
          </div>
        </div>
      </div>
    </Card>
  </section>
);

export default PressSection;
