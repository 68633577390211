import type { ComponentProps } from "react";
import Image from "next/image";
import Link from "next/link";
import { renderRichText } from "@storyblok/react";
import { format } from "date-fns";
import { de } from "date-fns/locale";

import { Button } from "@/components/ui/button";
import { Headline } from "@/components/typography/Headline";
import { Tag } from "@/components/typography/Tag";
import { RichText } from "@/components/typography/RichText";
import { cn } from "@/lib/utils";
import type { EventStoryblok } from "@/component-types-sb";

type EventProps = {
  event: EventStoryblok;
  image?: string;
  reverse?: boolean;
} & ComponentProps<"article">;

const defaultImage = "/images/shape4.svg";

const Event = ({
  event,
  image = defaultImage,
  reverse = true,
  ...rest
}: EventProps) => {
  const date = new Date(event.date.replace(" ", "T"));
  const weekday = format(date, "EEEE", { locale: de });
  const formattedDate = format(date, "dd. MMM yyyy", { locale: de });

  return (
    <article
      className={cn(
        "bg-white rounded-3xl flex flex-col-reverse md:flex-row gap-14 p-6 mb-8",
        {
          ["md:flex-row-reverse"]: reverse,
        },
        rest.className
      )}
    >
      <div className="md:w-3/5 flex p-4 md:p-14 flex-col items-start">
        <Headline as="h3" size="medium" className="my-4">
          {event.title}
        </Headline>

        <div className="flex justify-start items-center gap-2 flex-wrap">
          {event.tags?.map((name: number | string, index: number) => (
            <Tag
              key={index}
              label={name.toLocaleString()}
              href={`/search?tag=${name}`}
              size="small"
            />
          ))}
        </div>

        <RichText
          html={renderRichText(event.text)}
          className="text-xl my-4 line-clamp-5"
        />

        <Link href={event.link.cached_url || "#"}>
          <Button className="bg-sun-100 text-black h-full rounded-full hover:bg-sun-75">
            {event.cta || "Mehr erfahren"}
          </Button>
        </Link>
      </div>
      <div className="md:w-2/5 relative">
        <Link href={event.link.cached_url || "#"}>
          <Image
            src={image}
            alt=""
            width={420}
            height={420}
            className="w-full h-full aspect-square object-contain rounded-2xl"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
            <Headline size="tall" as="p">
              {weekday}
              <br />
              {formattedDate}
            </Headline>
          </div>
        </Link>
      </div>
    </article>
  );
};

export { Event };
