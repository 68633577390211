import { Fragment } from "react";
import { useSearchParams } from "next/navigation";
import { storyblokEditable } from "@storyblok/react/rsc";

import { ImageCard } from "@/components/layout/ImageCard";
import { ContentCard } from "@/components/layout/ContentCard";
import { Categories } from "@/components/layout/Categories";
import type { ResultSectionStoryblok } from "@/component-types-sb";
import { componentName } from "@/lib/utils/string";

type ResultSectionProps = {
  blok: ResultSectionStoryblok;
};

const ResultSection = ({ blok }: ResultSectionProps) => {
  const searchParams = useSearchParams();
  const query = searchParams.get("category")?.toLocaleLowerCase() || undefined;

  const items = blok.items?.filter((item: any) =>
    query
      ? item.content.category?.name?.toLocaleLowerCase() ===
        query.toLocaleLowerCase()
      : true
  );

  return (
    <>
      <section className="p-4 md:p-14 container max-w-7xl mx-auto">
        <Categories label="Themenwelten" />
      </section>

      <section className="p-4 md:p-14" {...storyblokEditable(blok)}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {items?.map((nestedBlok: any, index: number) => (
            <Fragment key={index}>
              {index <= 1 && (
                <div className="md:col-span-3">
                  <ContentCard
                    tagline={componentName(nestedBlok.content?.component)}
                    headline={nestedBlok.content?.title}
                    text={nestedBlok.content?.except}
                    color={index === 0 ? "sky" : "sun"}
                    image={nestedBlok.content?.image?.filename}
                    href={`/${nestedBlok.full_slug}`}
                    tags={nestedBlok.content?.tags}
                    mask
                    reverse={index === 0}
                  />
                </div>
              )}

              {index > 1 && (
                <ImageCard
                  tagline={componentName(nestedBlok.content?.component)}
                  headline={nestedBlok.content?.title}
                  image={nestedBlok.content?.image?.filename}
                  href={`/${nestedBlok.full_slug}`}
                  tags={nestedBlok.content?.tags}
                />
              )}
            </Fragment>
          ))}
        </div>
      </section>
    </>
  );
};

export default ResultSection;
