/* eslint-disable @next/next/no-img-element */
"use client";

import { useEffect } from "react";
import type { ReactNode } from "react";
import Script from "next/script";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { NuqsAdapter } from "nuqs/adapters/next/app";

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import type { FeatureFlags } from "@/lib/feature-flags";
import { FeaturesContext } from "@/lib/hooks/useFeature";
import { useConsent } from "@/lib/hooks/useConsent";

// Check that PostHog is client-side (used to handle Next.js SSR)
// see https://posthog.com/docs/libraries/next-js#app-router
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: "/ingest",
    ui_host: "https://us.posthog.com",
    persistence: "memory",
    // Enable debug mode in development or preview
    loaded: (posthog) => {
      if (process.env.VERCEL_ENV !== "production") {
        posthog.debug();
      }
    },
  });
}

type Props = {
  readonly features: FeatureFlags;
  readonly children: ReactNode;
};

export const Providers = ({ features, children }: Props) => {
  const { statistics } = useConsent();

  useEffect(() => {
    posthog.set_config({
      persistence: statistics ? "localStorage+cookie" : "memory",
      disable_persistence: !statistics,
    });
  }, [statistics]);

  return (
    <FeaturesContext.Provider value={features}>
      <PostHogProvider client={posthog}>
        <NuqsAdapter>
          {children}
          {statistics && (
            <>
              <GoogleAnalytics
                gaId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID!}
              />

              <GoogleTagManager gtmId="AW-16845234362" />

              {/* Google Tag Manager */}
              {/* <Script
                id="gtag-external"
                strategy="afterInteractive"
                src="https://www.googletagmanager.com/gtag/js?id=AW-16845234362"
              />
              <Script
                id="gtag-inline"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-16845234362');`,
                }}
              /> */}

              {/* Clarity Script */}
              <Script
                id="clarity-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "qgfv408nn9");`,
                }}
              />

              {/* Meta Pixel Code */}
              <Script
                id="facebook-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '570233909394400');
            fbq('track', 'PageView');
          `,
                }}
              />
              <noscript>
                <img
                  width="1"
                  height="1"
                  style={{ display: "none" }}
                  src="https://www.facebook.com/tr?id=570233909394400&ev=PageView&noscript=1"
                  alt=""
                />
              </noscript>
              {/* End Meta Pixel Code */}
            </>
          )}
        </NuqsAdapter>
      </PostHogProvider>
    </FeaturesContext.Provider>
  );
};
