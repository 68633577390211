import Link from "next/link";
import { track } from "@vercel/analytics";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

import { Button } from "@/components/ui/button";
import type { CtaStoryblok } from "@/component-types-sb";
import { cn } from "@/lib/utils";

type CtaProps = {
  blok: CtaStoryblok;
};

const Cta = ({ blok }: CtaProps) => {
  const href =
    blok?.link.linktype === "email"
      ? `mailto:${blok?.link.email}`
      : blok?.link.linktype === "url"
      ? blok?.link.cached_url
      : blok?.link.anchor
      ? `/${[blok?.link.cached_url, blok.link.anchor].join("#")}`
      : blok?.link.linktype === "story"
      ? `/${blok?.link.cached_url}`
      : "#";

  return (
    <Link
      href={href || "#"}
      target={blok.link?.target || "_self"}
      aria-label="Mehr Informationen"
      {...storyblokEditable(blok)}
    >
      {blok.type === "button" ? (
        <Button
          onClick={() => {
            blok.tracking && track(blok.tracking);
          }}
          className={cn("rounded-full px-8", {
            "bg-mylife-green hover:bg-mylife-green text-white":
              blok.variant === "mylife-green",
          })}
        >
          {blok.arrow && <ArrowRightIcon className="w-4 h-4" />}
          {blok.label}
        </Button>
      ) : (
        <div className="inline-flex gap-2 items-center underline">
          <ArrowRightIcon className="w-4 h-4" />
          {blok.label}
        </div>
      )}
    </Link>
  );
};

export default Cta;
