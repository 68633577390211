"use client";

import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import type { PartnerStoryblok } from "@/component-types-sb";

type PartnerProps = {
  blok: PartnerStoryblok;
};

const Partner = ({ blok }: PartnerProps) => {
  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header className="mb-8 lg:mb-16">
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <div className="container max-w-7xl mx-auto md:px-8">
        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </article>
  );
};

export default Partner;
