import type { MultilinkStoryblok } from "@/component-types-sb";

export function getUrlFromLinkObject(link: MultilinkStoryblok | undefined) {
  if (!link) return "";
  if (link.linktype === "story") {
    if (link.story?.full_slug) {
      return "/" + link.story.full_slug;
    } else {
      return link.cached_url;
    }
  }
  return link.url;
}
