"use client";

import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";
import { renderToStaticMarkup } from "react-dom/server";
import { useEffect, useState } from "react";
import { useAuth } from "@clerk/nextjs";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { InterviewStoryblok } from "@/component-types-sb";
import { usePostHog } from "posthog-js/react";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Separator } from "@/components/ui/separator";
import { Subline } from "@/components/typography/Subline";
import { Headline } from "@/components/typography/Headline";
import { ImageCard } from "@/components/layout/ImageCard";
import { RichText } from "@/components/typography/RichText";
import { VideoSkeleton } from "@/components/layout/Skeleton";
import InfoBox from "@/components/storyblok/InfoBox";
import Ad from "@/components/storyblok/Ad";
import { formatDate } from "@/lib/utils/date";
import { useConsent } from "@/lib/hooks/useConsent";
import { useIntersectionObserver } from "@/lib/hooks/useIntersectionObserver";
import { getInterviews } from "@/lib/storyblok/getInterviews";

const MuxPlayer = dynamic(() => import("@mux/mux-player-react"), {
  ssr: false,
  loading: () => <VideoSkeleton className="w-full h-full" />,
});

type InterviewProps = {
  blok: InterviewStoryblok;
  createdAt: string;
};

const Interview = ({ blok, createdAt }: InterviewProps) => {
  const [interviews, setInterviews] = useState<InterviewStoryblok[]>([]);
  const { userId } = useAuth();
  const { statistics } = useConsent();
  const posthog = usePostHog();
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
    threshold: 0.5,
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getInterviews({
        filter_query: {
          _uid: { not_in: blok._uid },
        },
      });
      setInterviews(data);
    };
    fetchData();
  }, [blok]);

  useEffect(() => {
    if (isIntersecting) {
      posthog.capture("sponsor view", { title: blok.title });
    }
  }, [isIntersecting, blok, posthog]);

  return (
    <div
      className="flex justify-between px-4 pt-8 pb-16 lg:pt-16 lg:pb-24 mx-auto max-w-screen-xl"
      {...storyblokEditable(blok)}
    >
      <article className="mx-auto w-full max-w-5xl format format-sm sm:format-base lg:format-lg format-blue">
        <header className="mb-4 lg:mb-6 not-format">
          <Subline>
            <time dateTime={createdAt}>{formatDate(new Date(createdAt))}</time>
          </Subline>
          <Headline as="h1" size="huge" className="mb-4 lg:mb-6">
            {blok.title}
          </Headline>
        </header>

        <p className="lead text-2xl mb-8">{blok.subtitle}</p>

        {blok.playback_id ? (
          <MuxPlayer
            streamType="on-demand"
            playbackId={blok.playback_id}
            metadataVideoTitle={blok.title}
            metadataViewerUserId={userId || undefined}
            preload="auto"
            className="aspect-video mb-8"
            disableCookies={!statistics}
            accentColor="#fbc02d"
          />
        ) : (
          <Image
            src={blok.image?.filename || ""}
            alt={blok.image?.alt || ""}
            width={1280}
            height={720}
            className="mb-4"
          />
        )}

        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}

        <RichText
          html={renderRichText(blok.text, {
            resolver: (component, blok) => {
              switch (component) {
                case "info_box":
                  return renderToStaticMarkup(<InfoBox blok={blok} />);
                case "ad":
                  return renderToStaticMarkup(<Ad blok={blok} />);
              }
            },
          })}
        />

        <Separator className="my-4" />
        <h4 className="text-sm font-medium leading-none mb-4">Sponsor</h4>

        <div ref={ref}>
          {blok.logo?.filename && (
            <Link
              href={blok.link?.url || "#"}
              target={blok.link?.target || "_self"}
            >
              <Image
                src={blok.logo.filename}
                alt={blok.logo.alt || ""}
                width={300}
                height={300}
                className="float-end ml-4 mb-4 w-full sm:w-auto"
              />
            </Link>
          )}
          <RichText html={renderRichText(blok.sponsor)} />
        </div>

        <Headline as="p" size="tall" className="py-8">
          Weitere Interviews
        </Headline>

        <Carousel>
          <CarouselContent>
            {interviews?.map((nestedBlok) => (
              <CarouselItem className="md:basis-1/3" key={nestedBlok.id}>
                <ImageCard
                  key={nestedBlok.uuid}
                  tagline={nestedBlok.content?.topic}
                  headline={nestedBlok.content?.title}
                  image={nestedBlok.content?.image?.filename}
                  href={`/${nestedBlok.full_slug}`}
                  tags={nestedBlok.content?.tags}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </article>
    </div>
  );
};

export default Interview;
