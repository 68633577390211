import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";

import type { AssetLinkStoryblok } from "@/component-types-sb";

type AssetLinkProps = {
  blok: AssetLinkStoryblok;
};

const AssetLink = ({ blok }: AssetLinkProps) => {
  const href =
    blok?.link?.linktype === "url"
      ? blok?.link?.cached_url
      : blok?.link?.linktype === "story"
      ? `/${blok?.link.cached_url}`
      : "#";

  return (
    <Link
      href={href || "#"}
      target={blok.link?.target || "_self"}
      aria-label="Seite öffnen"
      className="cursor-pointer"
      {...storyblokEditable(blok)}
    >
      {blok.asset && (
        <Image
          src={blok.asset.filename}
          alt={blok.asset.alt || ""}
          className="w-auto min-h-12 max-h-20"
          width={128}
          height={128}
        />
      )}
    </Link>
  );
};

export default AssetLink;
