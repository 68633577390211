import { storyblokEditable } from "@storyblok/react/rsc";

import { Event } from "@/components/layout/Event";
import { SectionHeader } from "@/components/typography/SectionHeader";
import { CopyText } from "@/components/typography/CopyText";
import type { EventSectionStoryblok } from "@/component-types-sb";

type EventSectionProps = {
  blok: EventSectionStoryblok;
};

const EventSection = ({ blok }: EventSectionProps) => (
  <section
    id="event-section"
    className="p-4 md:p-14"
    {...storyblokEditable(blok)}
  >
    {blok.subline && blok.headline && (
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        size="huge"
      />
    )}

    {blok.text && <CopyText className="mb-16 max-w-2xl">{blok.text}</CopyText>}

    {blok.items?.map((event: any, index: number) => (
      <Event key={event.uuid} event={event.content} reverse={index % 2 === 0} />
    ))}
  </section>
);
export default EventSection;
