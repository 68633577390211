import { useEffect, useState } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";

import type {
  AdSpaceStoryblok,
  AdStoryblok,
  CampaignStoryblok,
} from "@/component-types-sb";
import Ad from "./Ad";
import { getCampaigns } from "@/lib/storyblok/getCampaigns";
import { randomItem } from "@/lib/utils/array";
import { cn } from "@/lib/utils";

type AdSpaceProps = {
  blok: AdSpaceStoryblok;
};

const AdSpace = ({ blok }: AdSpaceProps) => {
  const [ad, setAd] = useState<AdStoryblok | undefined>(
    blok.ad as AdStoryblok | undefined
  );
  const [campaign, setCampaign] = useState<CampaignStoryblok>();
  const [isVisible, setIsVisible] = useState(false);

  const adSpace = blok.ad_space as string | "";
  const inSidebar = adSpace === "blog_sidebar";

  // Show Skyscraper ad after scrolling down 800px
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const distanceFromBottom =
        documentHeight - (scrollPosition + windowHeight);

      setIsVisible(scrollPosition >= 800 && distanceFromBottom > 800);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const campaigns = await getCampaigns({
          filter_query: {
            ad_spaces: { in: adSpace },
            disabled: { is: false },
          },
        });

        if (campaigns?.length > 0) {
          const campaign = randomItem(campaigns);
          setCampaign(campaign);

          const ads = campaign.content?.ads.filter((ad: AdStoryblok) =>
            ad.content?.ad_spaces?.includes(adSpace as string)
          );
          setAd(randomItem(ads));
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (!blok.ad) {
      fetchData();
    }
  }, [blok.ad, adSpace]);

  return (
    <section
      {...storyblokEditable(blok)}
      data-ad-space-name={adSpace}
      data-campaign-name={campaign?.name}
      data-ad-name={ad?.name}
      className={cn("opacity-100", {
        ["lg:fixed lg:top-4 lg:max-w-96 transition-opacity duration-300"]:
          inSidebar,
        ["opacity-0 pointer-events-none"]: inSidebar && !isVisible,
      })}
    >
      {ad?.content && <Ad blok={ad} campaign={campaign} className="mb-8" />}
    </section>
  );
};

export default AdSpace;
