"use client";

import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";

import type { PressStoryblok } from "@/component-types-sb";

type PressProps = {
  blok: PressStoryblok;
};

const Press = ({ blok }: PressProps) => {
  return (
    <article
      className="container max-w-7xl mx-auto md:px-8"
      {...storyblokEditable(blok)}
    >
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </article>
  );
};

export default Press;
