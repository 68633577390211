import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import type { FeatureListCardStoryblok } from "@/component-types-sb";

type FeatureListCardProps = {
  blok: FeatureListCardStoryblok;
};

const FeatureListCard = ({ blok }: FeatureListCardProps) => (
  <section
    className="p-4 md:p-14 container max-w-7xl mx-auto"
    {...storyblokEditable(blok)}
  >
    <Headline as="h2">{blok.headline}</Headline>

    <CopyText size="tall" className="max-w-4xl">
      {blok.text}
    </CopyText>

    <div className="grid md:grid-cols-3 gap-8 pt-8">
      {blok?.items?.map((nestedBlok: any) => (
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </div>
  </section>
);

export default FeatureListCard;
