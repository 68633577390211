import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Headline } from "@/components/typography/Headline";
import { CopyText } from "@/components/typography/CopyText";
import type { TextSectionStoryblok } from "@/component-types-sb";

type TextSectionProps = {
  blok: TextSectionStoryblok;
};

const TextSection = ({ blok }: TextSectionProps) => (
  <section className="p-4 md:p-14" {...storyblokEditable(blok)}>
    <article className="max-w-2xl">
      <Headline as="h3" size="tall" className="mb-16">
        {blok.headline}
      </Headline>
      <CopyText className="mb-4">{blok.text}</CopyText>

      {blok.image?.filename && (
        <Image
          src={blok.image.filename}
          alt={blok.image.alt || ""}
          className="w-full h-auto max-h-64 rounded-3xl object-cover object-center my-16"
          width={800}
          height={600}
        />
      )}

      {blok.body?.map((nestedBlok: any) => (
        <StoryblokComponent key={nestedBlok._uid} blok={nestedBlok} />
      ))}
    </article>
  </section>
);

export default TextSection;
