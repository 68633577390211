"use client";

import { useSearchParams } from "next/navigation";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react/rsc";
import type { BlogStoryblok } from "@/component-types-sb";

import { SectionHeader } from "@/components/typography/SectionHeader";
import { ImageCard } from "@/components/layout/ImageCard";
import { ContentCard } from "@/components/layout/ContentCard";
import { Categories } from "@/components/layout/Categories";
import { SearchForm } from "@/components/layout/SearchForm";
import { componentName } from "@/lib/utils/string";

type BlogProps = {
  blok: BlogStoryblok;
};

const Blog = ({ blok }: BlogProps) => {
  const searchParams = useSearchParams();
  const query = searchParams.get("category")?.toLocaleLowerCase() || undefined;

  const articles = blok.articles?.filter((article: any) =>
    query
      ? article.content.category?.name?.toLocaleLowerCase() ===
        query.toLocaleLowerCase()
      : true
  );

  return (
    <section {...storyblokEditable(blok)}>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <SectionHeader
          tagline="Blog"
          headline={blok.description || ""}
          center
        />

        <SearchForm fullWidth className="md:mx-48 mb-6" />

        <Categories className="justify-center" />

        <div className="mb-8">
          {blok.body?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>

        {!articles?.length && (
          <p className="text-center text-sm text-gray-500">
            Keine Artikel gefunden
          </p>
        )}

        <div className="break-container bg-white">
          <div className="container max-w-7xl mx-auto grid gap-8 lg:grid-cols-3 p-4 md:p-14">
            {articles?.map((article: any, index: number) =>
              [0, 1].includes(index) ? (
                <ContentCard
                  key={article.uuid}
                  tagline={componentName(article.content?.component)}
                  headline={article.content?.title}
                  text={article.content?.description}
                  image={article.content?.image?.filename}
                  href={`/${article.full_slug}`}
                  tags={article.content?.tags}
                  reverse={index === 0}
                  color={index === 0 ? "sky" : "sun"}
                  mask
                  className="col-span-3"
                />
              ) : (
                <ImageCard
                  key={article.uuid}
                  tagline={article.content?.topic}
                  headline={article.content?.title}
                  image={article.content?.image?.filename}
                  href={`/${article.full_slug}`}
                  tags={article.content?.tags}
                />
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
