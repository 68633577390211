import Link from "next/link";
import { Fragment } from "react";
import { storyblokEditable } from "@storyblok/react/rsc";
import { ArrowRightIcon } from "lucide-react";

import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { SectionHeader } from "@/components/typography/SectionHeader";
import { Headline } from "@/components/typography/Headline";
import type { ListSectionStoryblok } from "@/component-types-sb";

type ListSectionProps = {
  blok: ListSectionStoryblok;
};

const ListSection = ({ blok }: ListSectionProps) => {
  return (
    <section
      className="bg-white mx-1 md:mx-0 p-4 md:p-14 rounded-3xl"
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline}
        headline={blok.headline}
        size="huge"
        className="mb-14"
      />

      {blok?.items?.map((nestedBlok: any, index: number) => (
        <Fragment key={nestedBlok.id}>
          <Headline as="h3" size="tall" className="mb-4">
            {nestedBlok.content?.title}
          </Headline>
          <p className="text-xl mb-4 line-clamp-5">
            {nestedBlok.content?.except}
          </p>

          <Link
            href={`/${nestedBlok.full_slug}`}
            aria-label="Pressemeldung weiterlesen"
          >
            <Button type="submit" className="rounded-full">
              <ArrowRightIcon className="mr-1 h-4 w-4" />
              Weiterlesen
            </Button>
          </Link>

          <Separator className="my-8" />
        </Fragment>
      ))}
    </section>
  );
};

export default ListSection;
