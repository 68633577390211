import { storyblokEditable } from "@storyblok/react/rsc";
import { useUser } from "@clerk/nextjs";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { ImageCard } from "@/components/layout/ImageCard";
import { SectionHeader } from "@/components/typography/SectionHeader";
import { CopyText } from "@/components/typography/CopyText";
import { cn } from "@/lib/utils";
import type { SliderStoryblok } from "@/component-types-sb";

type SliderPops = {
  blok: SliderStoryblok;
};

const Slider = ({ blok }: SliderPops) => {
  const { user } = useUser();

  const hasPermission =
    !blok.roles?.length ||
    blok.roles?.includes(user?.publicMetadata.role as string);

  if (!hasPermission) {
    return null;
  }

  return (
    <div
      className="mx-auto max-w-7xl px-6 lg:px-8 py-12"
      {...storyblokEditable(blok)}
    >
      <SectionHeader
        tagline={blok.subline || ""}
        headline={blok.headline}
        className="mb-8"
      />
      {blok.text && <CopyText className="mb-8 max-w-2xl">{blok.text}</CopyText>}

      <Carousel>
        <CarouselContent>
          {blok.items?.map((nestedBlok: any) => (
            <CarouselItem className="md:basis-1/3" key={nestedBlok.id}>
              <ImageCard
                key={nestedBlok.uuid}
                tagline={nestedBlok.content?.topic}
                headline={nestedBlok.content?.title}
                image={nestedBlok.content?.image?.filename}
                href={`/${nestedBlok.full_slug}`}
                tags={nestedBlok.content?.tags}
                className={cn({
                  ["bg-white"]: blok.color === "white",
                })}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default Slider;
